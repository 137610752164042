import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const JobPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className='flex w-full items-center dark:bg-gray py-12 px-6 justify-center scroll-smooth '>
      {helmet || ""}
      <div className='flex flex-col justify-between bg-white dark:bg-grey rounded-lg border border-black mb-6 py-5 px-4'>
        <div className='border-4 border-black rounded-lg'>
          <div className='border-2'>
            <h1 className=' my-8 text-3xl text-center underline '>{title}</h1>
            <p className='m-8'>{description}</p>
            <PostContent className='m-8' content={content} />
            <button
                  type="button"
                  className="flex bg-bristol-olive p-1 rounded mx-auto mb-8"
                >
                  <Link
                    key='apply'
                    to='/apply'
                    className='md:text-base lg:text-xl hover:-mb-1 hover:text-white hover:text-2xl p-2 m-3,
                          px-3 py-2 rounded-md font-medium'
                  >
                    Apply Now
                  </Link>
                </button>
          </div>
        </div>
      </div>
    </section>
  );
};

JobPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const JobPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout className='font-sans'>
      <JobPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate='%s | Job'>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name='description'
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

JobPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default JobPost;

export const pageQuery = graphql`
  query JobPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
